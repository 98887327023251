import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { ReactTypeformEmbed } from 'react-typeform-embed';

import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import Faq from "../components/faq"
import Img from "gatsby-image"
import { Container, Row, Col, Button, Carousel, Navbar, Nav, Jumbotron, CardDeck, Card } from 'react-bootstrap'
import { FaStar, FaFacebookF, FaInstagram } from 'react-icons/fa'
import * as queryString from "query-string"
import '../assets/css/thankyou.css';

const SecondPage = ({ data }) => (
  <Layout>
    <Container className="mainContainer" xs={12} md={6} lg={4}>
      <Container>
        <Row>
          <Col>
            <img
              src="/icons/icon-logo.svg"
              // width="200"
              height="40"
              className="d-inline-block align-top"
              alt="Essential Blankets"
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <h1>Thank you!</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              We hope you enjoy your purchase. All of our products are <b>designed in the USA</b> and crafted with great attention to detail.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Thank you for supporting Essential Blankets and our mission to help people sleep better. Here's a <b>special 20% discount</b> for your next purchase: <b>SELFCARE20</b></p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>Leave us a review please</h2>
            <Row>
              <Col className="centerText">
                <a href="https://yotpo.com/go/arQuBuLq">
                  <Button variant="primary" className="mainButton" size="lg" block>Leave Review</Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="footer-block">
          <Col>
            <div className="socialMediaLinks">
              <a className="fa fa-facebook" href="https://facebook.com/essentialblankets" target="_blank" rel="noopener noreferrer"><FaFacebookF /><span>fb</span></a>
              <a className="fa fa-instagram" href="https://instagram.com/essentialblankets" target="_blank" rel="noopener noreferrer"><FaInstagram /><span>ig</span></a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2>
              Care Instructions:
            </h2>
            <p>This is a full organic, carbon neutral product. We use organic cotton & lavender to scent the blanket. No additives. No chemicals. JUST lavender</p>
            <p>Gently hand wash with cold water. Avoid bleaching, soaking, fabric softener or hot water. Please do not tumble dry or dry clean. For drying, please dry lay out the blanket on a flat surface.</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>Questions? Email us at <i>hello@aessentialstore.com</i></p>
            <br></br>
          </Col>
        </Row>


        <Row>
          <Col>
            <h2>We'd love to get to know you better.</h2>
            <p>(Just a 1 question survey!)</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="surveyTypeform">
              <ReactTypeformEmbed url="https://essentialblankets.typeform.com/to/KO0XbnAg" />
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>

)

export default SecondPage

export const query = graphql`
query {
  ccLogo: file(relativePath: { eq: "cc_logos.png" }) {
    childImageSharp {
      fluid(maxWidth: 150) {
        ...GatsbyImageSharpFluid
      }
    }
  }    
  carouselImage1: file(relativePath: { eq: "women-lavender-2-compressed-v2.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  footerLogo: file(relativePath: { eq: "essential-blankets-footer.png" }) {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}

`
